import { CombinedError } from '@urql/core';

export function isNetworkError(error: unknown): boolean {
  if (error instanceof CombinedError) {
    return (
      (error.networkError && error.message.includes('[Network] Failed to fetch')) ||
      (error.graphQLErrors && error.graphQLErrors.length > 0)
    );
  }
  return false;
}
