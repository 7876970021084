import { titleize } from 'inflection';
import { range } from 'lodash';
import slugify from 'slugify';
import { Client } from 'urql';

import { Filter } from '@/models';

import { states, statesLong } from './AddressFormat';
import { loadFiltersFunction } from './Stock/functions';

interface Location {
  state: string;
  city?: string;
  suburb?: string;
}

export const regionStandard = (region: string) => slugify(region.toLowerCase().replace('-region', ''));
export const stateStandard = (state: string) => slugify(statesLong?.[state.toLowerCase()] || state.toLowerCase());

export const removeDash = (str?: string) => str?.replace(/-/g, ' ') || '';

export const getBody = (maybeBody: string, filterStore: StockFiltersFragment) =>
  filterStore.body?.find((bodyType) => removeDash(bodyType?.toUpperCase()) === removeDash(maybeBody?.toUpperCase())) ||
  '';

// checks against directoryStore.filterStore includes the make given
export const isMake = (maybeMake: string, filterStore: StockFiltersFragment) =>
  filterStore.make?.some((make) => removeDash(make?.make?.toUpperCase()) === removeDash(maybeMake?.toUpperCase()));

export const getMake = (maybeMake: string, filterStore: StockFiltersFragment) =>
  filterStore.make?.find(
    (makeType) => removeDash(makeType?.make?.toUpperCase()) === removeDash(maybeMake?.toUpperCase()),
  )?.make || '';

// Returns a boolean of whether `maybeCondition` is a condition
export const isCondition = (maybeCondition: string) => ['NEW', 'USED', 'DEMO'].includes(maybeCondition?.toUpperCase());

export const isModel = (maybeModel: string, filterStore: StockFiltersFragment) =>
  filterStore?.model?.some(
    (modelType) => removeDash(modelType?.model?.toUpperCase()) === removeDash(maybeModel?.toUpperCase()),
  );

export const getModel = (maybeModel: string, filterStore: StockFiltersFragment) =>
  filterStore?.model?.find(
    (modelType) => removeDash(modelType?.model?.toUpperCase()) === removeDash(maybeModel?.toUpperCase()),
  )?.model || '';

// Returns a boolean of whether `maybeState` is a state based on the only two states available
export const isState = (maybeState: string) =>
  ['WESTERN AUSTRALIA', 'VICTORIA', 'QUEENSLAND'].includes(removeDash(maybeState));

// Returns a boolean of whether `maybeCity` is a city (requires state to be set to filterSEO obj)
export const isCity = (maybeCity: string, state: string) => {
  if (maybeCity.toLowerCase() == 'perth' && removeDash(state) == 'WESTERN AUSTRALIA') {
    return true;
  }
  if (maybeCity.toLowerCase() == 'melbourne' && removeDash(state) == 'VICTORIA') {
    return true;
  }
  if (maybeCity.toLowerCase() == 'brisbane' && removeDash(state) == 'QUEENSLAND') {
    return true;
  }
  return false;
};

// Returns a boolean of whether `maybeSuburb` is a suburb based of dealerCodeToSuburb
export const isSuburb = (maybeSuburb: string, dealerCodeToSuburb: Record<string, Nullable<string>>) =>
  !!Object.keys(dealerCodeToSuburb).filter(
    (key) => cleanCmsSuburb(maybeSuburb) == cleanCmsSuburb(dealerCodeToSuburb?.[key] || ''),
  ).length;

// Returns an array of dealerIds which are in the suburb name given
export const suburbToDealerIds = (suburb: string, dealerCodeToSuburb: Record<string, Nullable<string>>) =>
  Object.keys(dealerCodeToSuburb).filter(
    (key) => cleanCmsSuburb(suburb) == cleanCmsSuburb(dealerCodeToSuburb?.[key] || ''),
  );

// Returns lowercase slugified version of any suburb strings that come from CMS EtherMap
// e.g. 'Essendon Fields, Melbourne   ' -> 'essendon-fields'
export const cleanCmsSuburb = (suburb: string) => suburb?.split(',')?.[0]?.toLowerCase()?.trim()?.replace(' ', '-');

// returns filterStore with model data if make is provided in url
// pathSegments now just contains the necessary filter params and no padding
// doesn't really matter if the url structure isn't correct here as parseUrlToFilters will not return anything anyway
// -> coupled to parseUrlToFilters
export const getModelBaseFilterStore = async (
  pathSegments: string[],
  urqlClient: Client,
  filterStore?: StockFiltersFragment,
) => {
  if (!!filterStore) {
    for (const segment of pathSegments) {
      const validMakeModel = returnValidMakeModel(segment, filterStore);
      if (!!validMakeModel?.make) {
        const makeFilter = new Filter('make', validMakeModel.make);
        return await loadFiltersFunction(urqlClient, [makeFilter]);
      }
    }
  }
  return filterStore;
};

// Returns a boolean of whether `maybeCity` is a city
export const validCity = (maybeCity: string) => {
  if (['perth', 'melbourne', 'brisbane'].includes(maybeCity.toLowerCase())) {
    return true;
  }
  return false;
};

export const getStateFromCity = (maybeCity: string) => {
  if (maybeCity.toLowerCase() == 'perth') {
    return 'western australia';
  }
  if (maybeCity.toLowerCase() == 'melbourne') {
    return 'victoria';
  }
  if (maybeCity.toLowerCase() == 'brisbane') {
    return 'queensland';
  }
  return null;
};

export const getCityFromState = (maybeState: string) => {
  if (maybeState.toLowerCase() == 'western australia') {
    return 'Perth';
  }
  if (maybeState.toLowerCase() == 'victoria') {
    return 'Melbourne';
  }
  if (maybeState.toLowerCase() == 'queensland') {
    return 'Brisbane';
  }
  return null;
};

/**
 * Extracts a type from a list of parts by iterating from the end to the beginning.
 *
 * @param {string[]} parts - The array of string parts to process.
 * @param getType - A function that takes a string and returns a type string.
 * @param {string} typeName - The name of the type to be returned in the result object.
 * @returns {object | null} - An object containing the type and its length if found, otherwise null.
 *
 * @example
 * const parts = ['new', 'york', 'city'];
 * const getType = (type) => type === 'NEW YORK' ? 'NY' : '';
 * const result = getTypeFromParts(parts, getType, 'state');
 * // result: { state: 'NY', length: 2 }
 */
export const getTypeFromParamParts = (parts: string[], getType: (type: string) => string, typeName: string) => {
  // start from the longest possible string and remove one part on each loop
  for (const x of range(parts.length - 1, -1)) {
    const maybeType = parts
      .slice(0, x + 1)
      .join(' ')
      ?.toUpperCase();
    const typeValue = getType(maybeType);
    // getType should return typeValue or an empty string if not found
    if (!!typeValue) {
      return { [typeName]: typeValue, length: x + 1 };
    }
  }
  return null;
};

export const returnValidMakeModel = (maybeMakeModel: string, filterStore: StockFiltersFragment) => {
  if (!maybeMakeModel) {
    return null;
  }

  const parts = maybeMakeModel.split('-');
  const makeAttribute = 'make';
  const makeResult = getTypeFromParamParts(parts, (maybeMake) => getMake(maybeMake, filterStore), makeAttribute);
  if (!makeResult || !makeResult[makeAttribute] || typeof makeResult?.[makeAttribute] !== 'string') {
    return null;
  }

  if (makeResult.length === parts.length) {
    return { make: makeResult.make };
  }

  const modelParts = parts.slice(makeResult.length);
  const model = modelParts.join(' ').toUpperCase();
  const modelValue = getModel(model, filterStore);
  if (!!modelValue) {
    return { make: makeResult.make, model: modelValue };
  }

  return null;
};

export const returnValidLocation = (
  maybeLocation: string,
  dealerCodeToSuburb: Record<string, Nullable<string>>,
): Nullable<Location> => {
  if (!maybeLocation) {
    return null;
  }

  const parts = maybeLocation.split('-');
  const stateAttribute = 'state';
  const stateResult = getTypeFromParamParts(
    parts,
    (maybeState) => (isState(maybeState) ? maybeState.toUpperCase() : ''),
    stateAttribute,
  );
  if (!stateResult || !stateResult[stateAttribute] || typeof stateResult[stateAttribute] !== 'string') {
    return null;
  }

  const state = stateResult[stateAttribute]; // for typescript to know that state is a string
  if (stateResult.length === parts.length) {
    return { state };
  }

  const cityParts = parts.slice(stateResult.length);
  const cityAttribute = 'city';
  const cityResult = getTypeFromParamParts(
    cityParts,
    (maybeCity) => (isCity(maybeCity, state) ? maybeCity.toUpperCase() : ''),
    cityAttribute,
  );
  if (!cityResult || !cityResult[cityAttribute] || typeof cityResult[cityAttribute] !== 'string') {
    return null;
  }

  const city = cityResult[cityAttribute];
  if (cityResult.length === cityParts.length) {
    return { state, city };
  }

  const suburbParts = cityParts.slice(cityResult.length);
  const suburb = suburbParts.join(' ').toUpperCase();
  const isSuburbValid = isSuburb(suburb, dealerCodeToSuburb);
  if (isSuburbValid) {
    return { state, city, suburb };
  }
  return null;
};

export const getFilterSeoForLocation = (location: Location) => {
  return {
    state: titleize(location.state.replace('-', ' ')),
    stateId: states[location.state],
    city: !!location.city ? titleize(location.city.replace('-', ' ')) : undefined,
    suburb: !!location.suburb ? titleize(location.suburb.replace('-', ' ')) : undefined,
  };
};
